import React from "react"
import styled from "styled-components";

import { RegularText, Link, NavLink } from "../recipes";

import InstagramLink from "./InstagramLink";

import logoImage from "../assets/images/logo-white.svg";

const Container = styled.header``;

const Logo = styled.img.attrs({
  alt: "jonesing for cake logo"
})`
  height: calc(${props => props.theme.heightHeader});
`;

const Top = styled(RegularText)`
  height: calc(${props => props.theme.heightHeader} * 1.5);
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Bottom = styled(RegularText)`
  height: ${props => props.theme.heightLinks};
  flex: 1;
  align-items: center;
  justify-content: center;

  display: none;
  @media (min-width: ${props => props.theme.breakMedium}) {
    display: flex;
  }
`;

const Inner = styled.div`
  display: flex;
  flex: 1;
  padding: 0 ${props => props.theme.spacingSmall};
  max-width: ${props => props.theme.widthMain};
  align-items: center;

  @media (min-width: ${props => props.theme.breakMedium}) {
    padding: 0 ${props => props.theme.spacingRegular};
  }
`;

const TopInner = styled(Inner)`
  justify-content: space-between;
`;

const BottomInner = styled(Inner)``;

const Contact = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.breakMedium}) {
    display: flex;
    align-items: center;
  }
`;

const Instagram = styled(InstagramLink)`
  margin-left: ${props => props.theme.spacingRegular};
`;

const MenuLink = styled(Link)`
  display: flex;

  @media (min-width: ${props => props.theme.breakMedium}) {
    display: none;
}
`;

const MenuIcon = styled.div`
  height: ${props => props.theme.spacingRegular};
  width: ${props => props.theme.spacingRegular};
  margin: 0 ${props => props.theme.spacingSmall};

  background: linear-gradient(
    to bottom,
    black, black 20%,
    white 20%, white 40%,
    black 40%, black 60%,
    white 60%, white 80%,
    black 80%, black 100%
  );
}
`;

const Header = () => (
  <Container>
    <Top>
      <TopInner>
        <Link to="/">
          <Logo src={logoImage} alt="jonesing for cake logo" />
        </Link>
        <Contact>
          <Link
            to="mailto:hola@jonesingforcake.com.au"
            external="true"
            target="_top"
          >
            hola@jonesingforcake.com.au
          </Link>
          <Instagram overlay={true} />
        </Contact>
        <MenuLink to="/menu">
          <MenuIcon />
        </MenuLink>
      </TopInner>
    </Top>
    <Bottom>
      <BottomInner>
        <NavLink to="/cupcakes">cupcakes</NavLink>
        <NavLink to="/cakes">cakes</NavLink>
        <NavLink to="/flavours">flavours</NavLink>
        <NavLink to="/cookies">cookies</NavLink>
        <NavLink to="/about">about</NavLink>
        <NavLink to="/contact">contact</NavLink>
      </BottomInner>
    </Bottom>
  </Container>
);

export default Header;
