import styled from "styled-components";

import LargeText from "./text/LargeText";
import RegularText from "./text/RegularText";

const Section = styled.section`
  margin-bottom: ${props => props.theme.spacingRegular};
`;

const Header = styled(LargeText)`
  display: flex;
  align-items: center;
  font-weight: ${props => props.theme.fontWeightBold};
`;

const Body = styled(RegularText)`
  margin-bottom: ${props => props.theme.spacingTiny};
`;

Section.Header = Header;
Section.Body = Body;

export default Section;