import styled from "styled-components";

import Link from "./Link";

const NavLink = styled(Link)`
  margin-right: ${props => props.theme.spacingSmall};
  position: relative;
  text-decoration: none;

  &.active {
    &::after {
      position: absolute;
      left: 0;
      bottom: 0;
      height: ${props => props.theme.spacingSmall};
      width: 100%;
      content: "";
      background-color: ${props => props.theme.highlightColor2};
      z-index: -1;
    }
  }
`;

export default NavLink;
