import { fontSizeAsRem } from "./../utils/fonts";

import colors from "./colors";

const baseFontIndex = 10;

const spacings = {
  spacingHuge: "64px",
  spacingLarge: "48px",
  spacingRegular: "24px",
  spacingSmall: "16px",
  spacingTiny: "8px"
};

const breakpoints = {
  // Breakpoints lifted from Bootstrap 4
  breakHuge: "1199px",
  breakLarge: "991px",
  breakMedium: "768px",
  breakSmall: "575px"
};

const fontSizes = {
  // Base font sizes based on index into this 3:4 scale
  // https://designforhackers.com/blog/font-sizes/
  fontSizeHero: fontSizeAsRem(baseFontIndex),
  fontSizeTitle1: fontSizeAsRem(baseFontIndex - 1),
  fontSizeTitle2: fontSizeAsRem(baseFontIndex - 2),
  fontSizeTitle3: fontSizeAsRem(baseFontIndex - 3),
  fontSizeLarge: fontSizeAsRem(baseFontIndex - 4),
  fontSizeRegular: fontSizeAsRem(baseFontIndex - 5),
  fontSizeSmall: fontSizeAsRem(baseFontIndex - 6),
  fontSizeTiny: fontSizeAsRem(baseFontIndex - 7),

  fontWeightBold: 600,
  fontWeightRegular: 400,
  fontWeightLight: 300,

  titleLineHeight: "100%",
  baseLineHeight: "140%"
};

const shadows = {
  shadowLevel1: `1px 1px 3px 0px ${colors.maskGrey}`,
  shadowLevel2: `1px 1px 4px 1px ${colors.maskGrey}`,
  shadowLevel3: `1px 1px 5px 2px ${colors.maskGrey}`
};

const baseTheme = {
  heroFont: "Poppins, Helvetica Neue, Roboto, Segoe UI",
  baseFont: "Poppins, Helvetica Neue, Roboto, Segoe UI",
  monospaceFont: "Courier New",

  ...fontSizes,

  bgPrimary: colors.white,
  bgSecondary: colors.extraLightGrey,
  bgHeader: colors.black,

  textPrimary: colors.darkGrey,
  textSecondary: colors.baseGrey,
  textOverlay: colors.white,

  highlightColor1: colors.gainsboro,
  highlightColor2: colors.bubbleGum,
  highlightColor3: colors.lavenderPurple,
  errorColor: "red",
  transparentColor: colors.transparent,

  maskColor: colors.maskGrey,
  darkMaskColor: colors.darkMaskGrey,

  ...spacings,

  baseGutter: spacings.spacingLarge,
  baseRadius: "3px",
  baseTransitionTime: "0.2s",

  heightHeader: spacings.spacingHuge,
  heightLinks: spacings.spacingLarge,
  heightFooter: spacings.spacingHuge,

  widthMain: breakpoints.breakHuge,

  ...breakpoints,
  ...shadows,

  zIndexNavbar: 50,
  zIndexDropdown: 100,
  zIndexDropdownMask: 99,
  zIndexModal: 150
};

export default baseTheme;
