import styled from "styled-components";

import BaseText from "./BaseText";

const LargeText = styled(BaseText)`
  font-family: ${props => props.theme.baseFont};
  font-size: ${props => props.theme.fontSizeLarge};
  font-weight: ${props => props.theme.fontWeightLight};
`;

export default LargeText;
