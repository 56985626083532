import React from "react";
import styled from "styled-components";

import logo from "../assets/images/instagram.svg";
import logoOverlay from "../assets/images/instagram.svg";

const Link = styled.a`
  display: flex;

  padding: ${props => props.theme.spacingRegular};
  @media (min-width: ${props => props.theme.breakMedium}) {
    padding: 0;
  }
`;

const imageDimensions = "30px";
const Image = styled.img`
  height: ${imageDimensions};
  width: ${imageDimensions};
`;

export default ({ onClickLink, overlay, className }) => (
  <Link
    href="https://instagram.com/jonesingforcake"
    target="_blank"
    onClick={onClickLink}
    rel="noopener"
    className={className}
  >
    <Image src={overlay ? logoOverlay : logo} alt="Instagram" />
  </Link>
);
