import styled from "styled-components";

import BaseText from "./BaseText";

const Title2 = styled(BaseText)`
  font-family: ${props => props.theme.heroFont};
  font-size: ${props => props.theme.fontSizeTitle2};
  font-weight: ${props => props.theme.fontWeightBold};

  line-height: ${props => props.theme.titleLineHeight};
  margin-bottom: ${props => props.theme.spacingSmall};
`;

export default Title2;
