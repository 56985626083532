import { darken, lighten, setAlpha } from "./../utils/colors";

const baseGrey = "#5F697A";
const darkGrey = darken(baseGrey, 25);
const lightGrey = lighten(baseGrey, 40);
const extraLightGrey = lighten(baseGrey, 55);
const maskGrey = setAlpha(baseGrey, 0.3);
const darkMaskGrey = setAlpha(darkGrey, 0.6);

// branding colours
const lavenderPurple = "#A17DA7";
const gainsboro = "#D8E2DC";
const mistyRose = "#FFE5D9";
const bubbleGum = "#FFCAD4";
const nadeshikoPink = "#F4ACB7";

const colors = {
  darkGrey,
  baseGrey,
  lightGrey,
  extraLightGrey,
  maskGrey,
  darkMaskGrey,

  lavenderPurple,
  gainsboro,
  mistyRose,
  bubbleGum,
  nadeshikoPink,

  white: "#fff",
  black: "#000",
  transparent: "transparent"
};

export default colors;
