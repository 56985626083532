import React from "react"
import styled from "styled-components";

import { SmallText } from "../recipes";

const Container = styled.footer`
  height: ${props => props.theme.heightHeader};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled(SmallText)`
  color: ${props => props.theme.textSecondary};
  text-align: center;
`;

const Inner = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.widthMain};
`;

const Footer = () => (
  <Container>
    <Inner>
      <Text>With <span role="img" aria-label="love">❤️</span> from Perth</Text>
      <Text>© jonesingforcake {new Date().getFullYear()}</Text>
    </Inner>
  </Container>
)

export default Footer
