import styled from "styled-components";

import RegularText from "./text/RegularText";

const List = styled.ul`
  list-style: initial;
  margin-bottom: ${props => props.theme.spacingTiny};
`;

const Item = styled.li`
  font-size: ${props => props.theme.fontSizeLarge};
  margin-bottom: ${props => props.theme.spacingTiny};
  list-style-type: none;
  margin-left: ${props => props.theme.spacingLarge};

  &:before {
    display: inline-block;
    content: "◼";
    margin-left: -${props => props.theme.spacingLarge};
    color: ${props => props.theme.highlightColor2};
    width: ${props => props.theme.spacingLarge};
    font-size: ${props => props.theme.fontSizeRegular};
  }
`;

const SubItem = styled(Item)`
  margin-left: ${props => props.theme.spacingRegular};

  &:before {
    margin-left: -${props => props.theme.spacingRegular};
    width: ${props => props.theme.spacingRegular};
    font-size: ${props => props.theme.fontSizeSmall};
  }

  &:first-child {
    margin-top: ${props => props.theme.spacingTiny};
  }
`;

const Text = styled(RegularText)`
  display: inline;
`;

List.Item = Item;
List.SubItem = SubItem;
List.Text = Text;

export default List;
