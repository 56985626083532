import styled from "styled-components";

import BaseText from "./BaseText";

const Title3 = styled(BaseText)`
  font-family: ${props => props.theme.heroFont};
  font-size: ${props => props.theme.fontSizeTitle3};

  line-height: ${props => props.theme.titleLineHeight};
  margin-bottom: ${props => props.theme.spacingSmall};
`;

export default Title3;
