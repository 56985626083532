import React from "react";
import { Link as GatsbyLink } from "gatsby"
import styled, { css } from "styled-components";

const LinkStyling = css`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

const InternalLink = styled(GatsbyLink)`
  ${LinkStyling}
`;

const ExternalLink = styled.a`
  ${LinkStyling}
`;

const Link = ({to, external, children, className, target = "_blank"}) => {
  return external ? 
    <ExternalLink href={to} target={target} className={className}>{children}</ExternalLink> : <InternalLink to={to} className={className} activeClassName="active">{children}</InternalLink>;
}

export default Link;
