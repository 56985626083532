import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { IntlProvider } from "react-intl";
import styled, { ThemeProvider } from "styled-components";

import Header from "./Header"
import Footer from "./Footer"

import { messages } from "../i18n";
import theme from "../theme";

const currentLocale = "en";

const MainContainer = styled.main`
  display: flex;
  flex-direction: column;

  margin: ${props => props.theme.spacingTiny} auto 0 auto;
  max-width: ${props => props.theme.widthMain};
  box-sizing: border-box; 
  min-height: calc(100vh - (${props => props.theme.heightHeader}*2) - ${props => props.theme.heightFooter});

  @media (min-width: ${props => props.theme.breakMedium}) {
    margin-top: ${props => props.theme.spacingRegular};
    min-height: calc(100vh - (${props => props.theme.heightHeader}*2) - ${props => props.theme.heightLinks} - ${props => props.theme.heightFooter});
  }
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <IntlProvider
          locale={currentLocale}
          key={currentLocale}
          messages={messages[currentLocale]}>
          <ThemeProvider theme={theme}>
            <React.Fragment>
              <Header siteTitle={data.site.siteMetadata.title} />
              <MainContainer>
                {children}
              </MainContainer>
              <Footer />
            </React.Fragment>
          </ThemeProvider>
        </IntlProvider>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
