import styled from "styled-components";

import BaseText from "./BaseText";

const TinyText = styled(BaseText)`
  font-family: ${props => props.theme.baseFont};
  font-size: ${props => props.theme.fontSizeTiny};
  font-weight: ${props => props.theme.fontWeightLight};
`;

export default TinyText;
