import React from "react";
import styled from "styled-components";
import { injectIntl } from "react-intl";

const Text = styled.div`
  color: ${props =>
    props.overlay ? props.theme.textOverlay : props.theme.textPrimary};

  line-height: ${props => props.theme.baseLineHeight};
`;

const BaseText = ({ id, text, values, className, overlay, intl, children }) => (
  <Text id={id} className={className} overlay={overlay}>
    {children == null ? intl.formatMessage({ id: text }, values) : children}
  </Text>
);

export default injectIntl(BaseText);
