import styled from "styled-components";

import BaseText from "./BaseText";

const Title1 = styled(BaseText)`
  font-family: ${props => props.theme.heroFont};
  font-size: ${props => props.theme.fontSizeTitle1};
  font-weight: ${props => props.theme.fontWeightBold};

  line-height: ${props => props.theme.titleLineHeight};
  margin-bottom: ${props => props.theme.spacingRegular};
`;

export default Title1;
