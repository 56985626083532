import styled from "styled-components";

import BaseText from "./BaseText";

const RegularText = styled(BaseText)`
  font-family: ${props => props.theme.baseFont};
  font-size: ${props => props.theme.fontSizeRegular};
  font-weight: ${props => props.theme.fontWeightLight};
`;

export default RegularText;
