import styled from "styled-components";

import Link from "./Link";

const ButtonLink = styled(Link)`
  display: inline-block;
  font-size: ${props => props.theme.fontSizeRegular};
  font-family: ${props => props.theme.baseFont};
  background-color: black;
  padding: ${props => props.theme.spacingSmall};
  margin: ${props => props.theme.spacingTiny} 0;
  color: ${props => props.theme.textOverlay};

  align-self: center;
  @media (min-width: ${props => props.theme.breakMedium}) {
    align-self: initial;
  }

  width: fit-content;

  &:hover, &:active {
    background-color: ${props => props.theme.highlightColor2};
    color: black;
  }
`;

export default ButtonLink;
