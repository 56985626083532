import { addLocaleData } from "react-intl";
import { default as localeEN } from "react-intl/locale-data/en";

import en from "./assets/i18n/en.json";

addLocaleData([...localeEN]);

export const messages = {
  en: {
    ...en
  }
};
