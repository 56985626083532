import styled from "styled-components";

import Title1 from "./text/Title1";

const PageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  
  position: relative;

  padding: ${props => props.theme.spacingSmall};
`;

const Text = styled(Title1)`
  position: relative;

  &::after {
    position: absolute;
    content: "";
    left: ${props => props.theme.spacingTiny};
    bottom: 0;
    height: ${props => props.theme.spacingRegular};
    width: 50%;
    background-color: ${props => props.theme.highlightColor1};
    z-index: -1;
  }
`;

PageContainer.Title = Text;

export default PageContainer;
