import tinycolor from "tinycolor2";

export const darken = (base, percentage) =>
  tinycolor(base)
    .darken(percentage)
    .toHexString();

export const lighten = (base, percentage) =>
  tinycolor(base)
    .lighten(percentage)
    .toHexString();

export const setAlpha = (base, opacity) =>
  tinycolor(base)
    .setAlpha(opacity)
    .toRgbString();
